
import { useState, useEffect, useRef } from "react";

import Pagination from '@mui/material/Pagination';
import MKTypography from "components/MKTypography";  
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKDatePicker from "components/MKDatePicker";
import MKBadge from "components/MKBadge";
import { appAuth } from "../../contexts/AppContext"
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment"; 
import SearchIcon from "@mui/icons-material/Search";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import MultipleSelect from "components/AppKey/MultipleSelect";
import Dropdowns from "components/AppKey/Dropdowns";

export default function AppLogs({appId}) { 

    const {appLogs} =  appAuth()
    const [logData, setLogData] = useState([])
    const [logDataRender, setLogDataRender] = useState([])
    const [filter, setFilter] = useState({appId:appId, resetSelection:0, type:"ALL", status:"ALL",  search:"", limit:25, offset: 0})
    const [total, setTotal] = useState(0)
    //const [totalPage, setTotalPage] = useState(0)
    const renderRef = useRef(false) 
    const [search, setSearch] = useState("")
    const applicationActions = [ "create", "update", "reset", "delete",  "getApp", "removeUser", "addUser", "searchUser", "deleteInvite", "createAppInvite", "removeAppSignup", "searchInvite",   "testEmailExtension"];
    const userActions = ["signup", "completeSignup", "invite",   "login", "loginComplete","loginAnonymous", "loginAnonymousComplete", "verify", "verifyComplete", "sendEmail", "getUser", "getApplication"];
    const [filterActions, setFilterActions] = useState(applicationActions.concat(userActions));
     
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
  
    const handleChangePage = (event, newPage) => {
        console.log("handleChangePage newPage ", newPage); 
        setPage(newPage);

        setFilter({
            ...filter,
            ["offset"]: newPage
        });
        
       
    };
  
    const handleChangeRowsPerPage = (value) => {

        console.log("handleChangeRowsPerPage newPage ", value);
        setFilter({
            ...filter,
            ["limit"]: value,
            ["offset"] : 1
        });
 
        setRowsPerPage(value);
        setPage(1); 
       
    };

    useEffect(() => {
        applyFilter()
    }, [page, rowsPerPage])



    const columns = [
        { id: 'number', label: 'Number' },
        { id: 'logType', label: 'Log Type', minWidth: 100, align: 'center' },
        { id: 'action',label: 'Action', minWidth: 170, align: 'center' },
        { id: 'status', label: 'Status', minWidth: 170, align: 'center'},
        { id: 'value', label: 'Value', align: 'center' },
        { id: 'createdAt', label: 'Created At', minWidth: 170, align: 'center' }
    ];
    
 
    function onSearchLogValueChange (evt)  { 
        let value = evt.target.value.toLowerCase()

        setSearch(value) 


        if (value != "") {
            setLogDataRender(
                logData.filter(data =>
                    data.value.toLowerCase().indexOf(value) >= 0
            )); 
        }
        else {
            setLogDataRender(logData); 
        } 
       

    }

    function onKeyEnterSearch(event){
       if(event.keyCode == 13) applyFilter()
    }
 

    const resetFilter = async () => {

        setFilter({
            ...filter, 
            ["type"]:"ALL",
            ["status"]:"ALL",
            ["search"]:"",
            ["fromDate"]:"",
            ["toDate"]:"",
            ["limit"]: 25,
            ["offset"] : 0,
            ["filterAction"] : [],
            ["resetSelection"]: Math.random()
        }); 
 
        applyFilter()
    }
    const applyFilter = async () => {
       

        console.log("applyFilter rowsPerPage", rowsPerPage)
        console.log("applyFilter page", page)
        console.log("applyFilter filter", filter)
       

        let data = await appLogs(filter)

        
        setLogData(prevItems => { 
            return [];
        }); 


        setLogDataRender(prevItems => { 
            return [];
        }); 

        if (!data || !data.logs) return

        setTotal(data.totalLog)

        for (let index = 0; index < data.logs.length; index++) {
            
            let element = makeRecord(data.logs[index], index); 
            element.number = page >= 1 ? ((page - 1)*rowsPerPage) + (index + 1 )   : (index + 1 )  
            setLogDataRender(prevItems => { 
                return [...prevItems, element];
            });  
            
            setLogData(prevItems => { 
                return [...prevItems, element];
            });  
           
        } 


    }
 
    
    useEffect(() => {
        
         
        async function fetchAppData() {  


            let data = await appLogs(filter)

            console.log("fetchAppData data", data)

            if (!data.logs) return

            for (let index = 0; index < data.logs.length; index++) {
            
                let element = makeRecord(data.logs[index], index); 
                element.number = index + 1 
              
                setLogData(prevItems => { 
                    return [...prevItems, element];
                });  
    
                setLogDataRender(prevItems => { 
                    return [...prevItems, element];
                });   
               
            } 

           
            setTotal(data.totalLog)
            
        }

        if (renderRef.current === false) {
 
            fetchAppData() 

            return () => {
                renderRef.current = true
                console.log("render clean up. ")
            }

        }
 
       

    }, [])

    const onDateChange = (value, dateStr, name) =>{
        console.log("onDateChange name" , name)
        console.log("onDateChange value" , value)

        setFilter({
            ...filter,
            [name]: dateStr
        });

       
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', hour12: true}
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const makeRecord = (item) => { 
        
        if (item.status == "success") item.status = <MKBadge variant="contained" badgeContent="success" color="success" size="xs" container />
        else item.status = <MKBadge variant="contained" badgeContent="error" color="error" size="xs" container />
        item.createdAt = formatDate(item.createdAt) 
        item.hide = false 
        return item
          
    }

    const onSelectedValue = (value, type)=> {
        setFilter({
            ...filter,
            [type]: value
        })

 

        if (type == "type"){
            if (value == "USER") {
                setFilterActions(prevItems => { 
                    return userActions;
                }); 
            }
            else if (value == "APP") {
                setFilterActions(prevItems => { 
                    return applicationActions;
                }); 
            }
            else if (value == "ALL") {
                setFilterActions(prevItems => { 
                    return applicationActions.concat(userActions)
                }); 
            }

            // setFilterActions(prevItems => { 
            //     return ["ALL",...prevItems];
            // }); 
        }
    }


    return(
    <MKBox p={2}>
        
        <MKTypography variant="h5">App Logs</MKTypography>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 6, md: 12 }}  alignItems="center" py={2}>  

            <Grid item  xs={3} sm={3} md={3}>
                <MultipleSelect label="Action"  list ={filterActions} width={200} multiple={true}  onSelectedValue={ (value) => onSelectedValue(value, "filterAction") } resetSelection = {filter.resetSelection}/>
                
            </Grid>
            <Grid item xs={3} sm={3} md={3}> 
                <MultipleSelect label="Type" defaultValue={filter.type}  list ={["ALL", "APP", "USER"]} width={200} onSelectedValue={ (value) => onSelectedValue(value, "type") } /> 
            </Grid>
            <Grid item xs={3} sm={3} md={3}> 
                <MultipleSelect label="Status" defaultValue={filter.status} list ={["ALL", "SUCCESS", "ERROR"]}  width={200} onSelectedValue={ (value) => onSelectedValue(value, "status") }/>
            </Grid>

            <Grid item xs={3} sm={3} md={3}> 
                <MKDatePicker input={{ placeholder: "From a date", name:'fromDate'}} options={{ maxDate: "today" }} onChange = { (selectedDates, dateStr) => onDateChange(selectedDates[0], dateStr, "fromDate") }/>
                
            </Grid>

            <Grid item xs={3} sm={3} md={3}> 
                <MKDatePicker input={{ placeholder: "To a date", name:'toDate'}} defaultDate={new Date()} options={{ maxDate: "today" }} onChange = { (selectedDates, dateStr) => onDateChange(selectedDates[0], dateStr, "toDate") }/>
            </Grid>

            <Grid item xs={3} sm={3} md={3}> 
            <MKInput
                            variant="standard"
                            placeholder="Search"
                            id = "searchValue"
                            name = "searchValue"
                            autoFocus={true}
                            value={search} 
                            onKeyDown = {onKeyEnterSearch}
                            onChange={e => {onSearchLogValueChange(e)} }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ), 
                            }} />
            </Grid>

            <Grid item xs={3} sm={3} md={3}> 
                <MKBox display="flex" alignItems="center">
                    <MKBox mr={2}>
                        <MKButton variant="gradient" color="primary" onClick={applyFilter}>
                            Submit
                        </MKButton>
                    </MKBox>
                    <MKButton variant="gradient" color="primary" onClick={resetFilter}>
                        Clear
                    </MKButton>
                </MKBox>
            </Grid>

        </Grid>

        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <MKBox alignItems="center" justifyContent="left" display="flex" p={2}>

                <MKTypography variant="body2" color="text">  Show:  </MKTypography> 
                <Dropdowns items={[25,  100, 150]} defaultValue = {rowsPerPage}  onChange={handleChangeRowsPerPage} /> 
                <MKTypography variant="body2" color="text"> entries  </MKTypography> 

                <MKBox px={3}> 
                    <MKTypography variant="body2" color="text"> Total Log: {total}  </MKTypography>
                </MKBox>
            </MKBox>
           
        <TableContainer sx={{ maxHeight: 650 }}>
            <Table responsive="sm">
                <thead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className="colunmHeader"
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
                </thead>
            <TableBody>
                {logDataRender
                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                    return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.number}>
                        {columns.map((column) => {
                        const value = row[column.id];
                        return (
                            <TableCell key={column.id} align={column.align} className="tableCell">
                            {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                })}
            </TableBody>

            
            </Table>
        </TableContainer>

        {Math.ceil(total/rowsPerPage) > 1 &&  
        <Grid container item xs={12} lg={5} justifyContent="center" mx="auto" py={3}> 
            <Pagination count={Math.ceil(total/rowsPerPage)} showFirstButton showLastButton page={page} onChange={handleChangePage} />
        </Grid>
        }
        
        </Paper>

    </MKBox>
    )    
}