import React from 'react';
 
import { Routes, Route } from "react-router-dom"  
import SignIn from './views/SignIn'; 
import SignUp from './views/SignUp';
 
import RestoreAccount from './views/RestoreAccount';
import Pricing from './views/Pricing';
import Apps from './views/Apps';
import Profile from 'views/Profile';
import AppDetail from 'views/AppDetail';
import SignOut from 'views/SignOut';
import Passkey from 'views/Passkey';
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Agreement from 'views/Agreement';
 

const reload = () => window.location.reload();
 
 
function App() {
 


  return (
    
    <ThemeProvider theme={theme}>
      <CssBaseline />
     
        <Routes> 
            <Route path="/apple-app-site-association" onEnter={reload} /> 
            <Route path="/signup" element={<SignUp/>} /> 
            <Route path="/signin" element={<SignIn/>} />  
            <Route path="/signout" element={<SignOut/>} /> 
            <Route path="/pricing" element={<Pricing /> }/> 
            <Route path="/passkey" element={<Passkey /> }/> 
            <Route path="/restore" element={<RestoreAccount /> }/> 
            <Route path="/apps" element={<Apps/> }/> 
            <Route path="/app/:appId" element={<AppDetail/> }/> 
            <Route path="/profile" element={<Profile/> }/> 
            <Route path="/agreement" element={<Agreement/> }/> 
            <Route path="*" element={<SignIn/>} /> 
            
        </Routes>
      
    </ThemeProvider>
 
    

  )
}

export default App;
