


import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext"
import {  useNavigate } from "react-router-dom"

import Icon from "@mui/material/Icon";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'; 
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";


export default function ProfileNav({mobileView}) {
    const { logout, currentUser} = useAuth()
    const navigate = useNavigate() 
    
    const [anchorElUser, setAnchorElUser] = useState(null);
    const loggedInUser = JSON.parse(localStorage.getItem("user"));  
    const settings = ['Profile', 'Passkey', 'Logout']; 
    
    const handleSelectUserMenu = (item) => {
        setAnchorElUser(null);

        if(item === "Logout") {
            logout() 
            navigate("/login")
            return
        }

        navigate(`/${item.toLowerCase()}`) 
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
      };
    

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    


    return( 
        <MKBox sx={{ flexGrow: 0 }}>
            
            <MKBox onClick={handleOpenUserMenu} sx={{ p: 0, cursor: "pointer" }} >
                {loggedInUser.avatar !== undefined ? <MKAvatar src={loggedInUser.avatar && loggedInUser.avatar.urlOrigin ? loggedInUser.avatar.urlOrigin : ""}  size="sm" alt= {loggedInUser.displayName} />  
                : 
                <MKAvatar alt= {loggedInUser.displayName} size="sm" bgColor="primary">  <Icon fontSize="xs">manage_accounts</Icon></MKAvatar>
                }
            </MKBox> 
        
            {!mobileView && 
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                {settings.map((setting) => (
                    <MenuItem key={setting} onClick={() => handleSelectUserMenu(setting)}>
                    <p textAlign="center" variant="body1" color="text" fontWeight="regular">{setting}</p>
                    </MenuItem>
                ))}
                </Menu>

            }
        </MKBox>
        

    );
}