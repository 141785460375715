


import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext" 
import {  useNavigate } from "react-router-dom"

 // Authentication pages components
import AppLayout from "components/Layouts/AppLayout";

export default function SignOut() {
   
    const { logout } = useAuth()
    const navigate = useNavigate() 

    useEffect(() => { 
        logout()
        navigate("/login")
    }, [])

    return (
        <> 
        <AppLayout>

        </AppLayout>
        </>

    );
}