/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
 
// Material Kit 2 PRO React components
import MKBox from "components/MKBox"; 
import MKTypography from "components/MKTypography"; 
import SimplePricingCard from "components/Cards/PricingCards/SimplePricingCard";

import { useAuth } from "../contexts/AuthContext"
import SiteLayout from "components/Layouts/SiteLayout";

function Pricing() {
   
  const {currentUser } = useAuth() 
  

  useEffect(() => {  
      console.log("currentUser ", currentUser)
  }, [currentUser]);

  return (
    <> 
      <SiteLayout>
      {currentUser && currentUser.planId ? 
        <MKBox component="section" >
          <Container>
            <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
              <MKTypography variant="h3">Pick the best plan for you</MKTypography>
              <MKTypography variant="body2" color="text">
                You have Free Unlimited Updates and Premium Support on each package.
              </MKTypography>
            </Grid>
            
            <Grid container spacing={3} mt={6}>
              <Grid item xs={12} sm={6} lg={3}>
                <SimplePricingCard
                  color="dark"
                  title="Free"
                  description="For teams experimenting with AppKey"
                  price={{ value: "$0", type: "month" }}
                  action={{ type: "internal", route: currentUser.planId ? "" : "/signup" , label:  currentUser.planId == "free-plan" ? "current plan" : "Buy Now"}}
                  specifications={["Up to 5 apps", "50 users per app"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SimplePricingCard
                  variant="gradient"
                  color="primary"
                  title="Developer"
                  description="For teams launching a first product using AppKey"
                  price={{ value: "$49", type: "month" }}
                  action={{ type: "internal", route: currentUser.planId.indexOf("devloper-program") >= 0 ? "": "/profile?showPlan=true", label: currentUser.planId.indexOf("devloper-program") >= 0 ? "current plan" : "Buy Now" }}
                  specifications={[
                    "Up to 20 apps",
                    "1,000 total active users"
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SimplePricingCard
                  variant="gradient"
                  color="primary"
                  title="Developer +"
                  description="For teams supporting a large product using AppKey"
                  price={{ value: "$299", type: "month" }}
                  action={{ type: "internal", route: currentUser.planId.indexOf("devloper-plus") >= 0 ? "": "/profile?showPlan=true", label: currentUser.planId.indexOf("devloper-plus") >= 0 ? "current plan" : "Buy Now" }}
                  specifications={[
                    "Up to 100 apps",
                    "10,000 total active users",
                    "$0.02 for each user beyond 10,000"
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SimplePricingCard
                  variant="gradient"
                  color="dark"
                  title="Enterprise"
                  description="For teams that need a custom AppKey solution"
                  price={{ value: "Email Us"}}
                  action={{ type: "internal", route: "mailto:info@cosync.io", label: "Contact Us" }}
                  specifications={[
                    "No limit on apps",
                    "Large set of users",
                    "Self-hosted solution",
                    "Custom Development" 
                  ]}
                />
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        :
        <MKBox component="section" >
          <Container>
            <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
              <MKTypography variant="h3">Pick the best plan for you</MKTypography>
              <MKTypography variant="body2" color="text">
                You have Free Unlimited Updates and Premium Support on each package.
              </MKTypography>
            </Grid>
            
            <Grid container spacing={3} mt={6}>
              <Grid item xs={12} sm={6} lg={3}>
                <SimplePricingCard
                  color="dark"
                  title="Free"
                  description="For teams experimenting with AppKey"
                  price={{ value: "$0", type: "month" }}
                  action={{ type: "internal", route: "/signup", label: "buy now" }}
                  specifications={["Up to 5 apps", "50 users per app"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SimplePricingCard
                  variant="gradient"
                  color="primary"
                  title="Developer"
                  description="For teams launching a first product using AppKey"
                  price={{ value: "$49", type: "month" }}
                  action={{ type: "internal", route: "/signup", label: "buy now" }}
                  specifications={[
                    "Up to 20 apps",
                    "1,000 total active users"
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SimplePricingCard
                  variant="gradient"
                  color="primary"
                  title="Developer +"
                  description="For teams supporting a large product using AppKey"
                  price={{ value: "$299", type: "month" }}
                  action={{ type: "internal", route: "/signup", label: "buy now" }}
                  specifications={[
                    "Up to 100 apps",
                    "10,000 total active users",
                    "$0.02 for each user beyond 10,000"
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SimplePricingCard
                  variant="gradient"
                  color="dark"
                  title="Enterprise"
                  description="For teams that need a custom AppKey solution"
                  price={{ value: "Email Us"}}
                  action={{ type: "internal", route: "mailto:info@cosync.io", label: "Contact Us" }}
                  specifications={[
                    "No limit on apps",
                    "Large set of users",
                    "Self-hosted solution",
                    "Custom Development" 
                  ]}
                />
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        
        }
     
      </SiteLayout>

    </>

  );


}

export default Pricing;
