import { useState, useEffect } from "react";
import MKTypography from "components/MKTypography"; 
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";

export default function AppMenu({items,  clickItemHandler}) { 

    const [menu, setMenu] = useState([])


    useEffect(() => {
        setMenu(items)
    }, [])

    const onClickItem = (data) => {
        console.log("onClickItem data ", data)

        setMenu(menu.map(item => {
            if (item.label === data.label) return {...item, active:true}
            else return {...item, active:false}
        }))

        clickItemHandler(data)
    }

    const renderMenus = () =>
   
        menu.map( item => (
        
        <Grid item  xs={3} md={1.5} key={item.index}>
            <MKButton color= "white" size="large" onClick={() => onClickItem(item)} fullWidth={true}> 
               
                <MKBox>
                    <Icon color= {item.active ? "primary": "secondary"} >{item.icon}</Icon>
                    <MKTypography variant="body2" color={item.active ? "primary": "secondary"} >{item.label}</MKTypography>
                </MKBox>
            </MKButton>
        </Grid>
            
        
    ));

      
    return(


        <Card sx={{  mb: 4, boxShadow: ({ boxShadows: { xxl } }) => xxl,}}  >
            <Grid  container >
                <Grid item xs={12}>
                   
                        <Grid justifyContent="center" container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={3}>
                        {
                            renderMenus()

                        }  
                        </Grid>   
                </Grid>     
            </Grid>   
        </Card>
    )
}