import { useState, useEffect } from "react"; 
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import localeList from "components/AppKey/locales.json"
import MenuItem from '@mui/material/MenuItem';
export default function LocaleSelection({list, placeholder, defaultValue, selectionHandler}) {

  
    const [items, setItems] = useState([])
    const [label, setPlaceHolder] = useState('Add Locale')
    useEffect(() => {

        console.log("list ", list)

        if(list)  setItems(list)
        else setItems(localeList.list) 

        if(placeholder) setPlaceHolder(placeholder)
    }, []) 

    const handleChange = (event) => {

        let item = items.filter(item => item.code == event.target.value)[0] 
        selectionHandler(item)
    };

    
    
    return (

        <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
        <InputLabel htmlFor="locale">{label}</InputLabel>
            <Select
                id="locale"
                inputProps={{
                    name: 'locale',
                    id: 'uncontrolled-native',
                }}
                defaultValue={defaultValue}
                onChange={handleChange}
            > 
           
            {
                items.map(function(locale, index){ 
                    return(
                        <MenuItem value={locale.code} key={index}> {locale.name} - {locale.code} </MenuItem> 
                    )
                })
            }


            </Select>
        </FormControl>

    )
}