
import MKInput from "components/MKInput"; 
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox"; 
import MKButton from "components/MKButton"; 
import MKTypography from "components/MKTypography";  
import Switch from "@mui/material/Switch";

import { Buffer } from 'buffer';
import { useState, useEffect } from "react";

export default function AppKey({app}) { 
    
    const encodeBase64 = (data) => {
        return Buffer.from(data).toString('base64');
    }
    
    const decodeBase64 = (data) => {
        return Buffer.from(data, 'base64').toString();
    }
    const [showRawKey, setShowRawKey] = useState(false);
    const [appPublicKey, setAppPublicKey] = useState(decodeBase64(app.appPublicKey));

    const showRawKeyHanlder = () =>{

        setShowRawKey(!showRawKey)

       
    }


    useEffect(() => {

        if(showRawKey === true) setAppPublicKey(encodeBase64(app.appPublicKey))
        else setAppPublicKey(decodeBase64(app.appPublicKey))

    }, [showRawKey])


    

    return (
        <MKBox p={2}> 

            <MKTypography variant="h5"   mb={3}>Application Keys</MKTypography>

            <MKTypography color="black" variant="body2" pr={4}>App Name: {app.name}</MKTypography>

            <MKTypography color="black" variant="body2" >App ID: {app.displayAppId}</MKTypography>

            <MKBox display="flex" alignItems="center">  
                <MKTypography color="black" variant="body2" >Public Key</MKTypography>
                <MKButton color="clear" size="large" onClick={() => {navigator.clipboard.writeText(appPublicKey)}}>
                    <Icon fontSize="large">copy</Icon>
                </MKButton> 
            </MKBox> 

            <MKBox display="flex" alignItems="center">
                <Switch checked={showRawKey}  onChange={showRawKeyHanlder}/>
                <MKTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    ml={1} >
                Show Raw Key
                </MKTypography>
            </MKBox> 

            <MKInput  disabled  multiline fullWidth rows={9} value={(appPublicKey)}  sx={{ maxWidth: 'md' }}/>


            <MKBox display="flex" alignItems="center">  
                <MKTypography color="black" variant="body2" >App Token</MKTypography>
                <MKButton color="clear" size="large" onClick={() => {navigator.clipboard.writeText(app.appToken)}} >
                    <Icon fontSize="large">copy</Icon>
                </MKButton> 
            </MKBox> 
            <MKInput  disabled  multiline fullWidth rows={10} value={app.appToken}  sx={{ maxWidth: 'md' }}/>

        </MKBox> 
    )

}