/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React example components
import DefaultNavbar from "components/DefaultNavbar";
import Footer from "components/Footer";
import {  useNavigate } from "react-router-dom"
// Material kit 2 PRO React page layout routes
import routes from "routes";

const loggedInUser = JSON.parse(localStorage.getItem("user"));  

function SiteLayout({ children }) { 
    
    return (
        <MKBox component="section">
            
            <MKBox width="100%" >
                <DefaultNavbar
                brand = "Pass Clef"
                routes={ loggedInUser ? routes.loggedInRoute : routes.route}   
                dark
                relative
                />
            </MKBox>
        
            <MKBox width="100%">
                <Grid container spacing={1} justifyContent="center"  mx="auto" pt={1}>
                    <Grid item xs={11} sm={12} md={12} lg={12} xl={10}>
                        {children}
                    </Grid> 
                </Grid> 
            </MKBox>

            <MKBox width="100%" pt={12}>
                <Footer />
            </MKBox>
        
        </MKBox>

        
    );
}
 

// Typechecking props for the SiteLayout
SiteLayout.propTypes = {
  coverHeight: PropTypes.string, 
  children: PropTypes.node.isRequired,
};

export default SiteLayout;
