

import { useState, useEffect } from "react";
import MKTypography from "components/MKTypography"; 
import Icon from "@mui/material/Icon";
import MKInput from "components/MKInput";
import Divider from "@mui/material/Divider";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import { appAuth } from "../../contexts/AppContext"
import { startRegistration} from '@simplewebauthn/browser';

export default function AppSignup({app, onSuccess, dismiss}) { 

    const {createUserSignup, verifiyUserSignup, signupComplete} =  appAuth()
    const [message, setMessage] = useState("")
    const [error, setErrorMessage] = useState("")
    const [data, setData] = useState({})

    useEffect(() => {
       setData({appId:app.appId})
    }, [])

    const onChangeInput = (evt) => { 

        setData({
            ...data,
            [evt.target.name]: evt.target.value,
        });
    }


    const onKeyEnterDown = (event) => {
        if(event.keyCode == 13) submitSignup()
    }

    const submitSignup = async () => {
        setErrorMessage("")
        setMessage("")

        if(data.finish) return

        if(!data.handle || !data.displayName) {
            setErrorMessage("Please enter all fields") 
            return;
        }

        if(data.verify) {
            if(!data.code) {
                setErrorMessage("Please enter all fields") 
                return;
            }

            signupCompleteHandler()
        }
        else {

            let result = await createUserSignup(data)
            
            if (result.error) {
                setErrorMessage(result.error.message) 

            }
            else {

                let attResp = await startRegistration(result);
                attResp.handle = data.handle; 
                attResp.appId = data.appId

                let signupData = await verifiyUserSignup(attResp)

                if(signupData['signup-token']) {
                    setMessage(result.message)

                    setData({
                        ...data,
                        verify: true,
                        'signup-token':signupData['signup-token']
                    });
                }
            }
            

        }

    }

    const signupCompleteHandler = async () => {

        let authn = await signupComplete(data);
        if (authn.error) {
            setErrorMessage(authn.error.message)
        }
        else {
            setData({
                ...data,
                finish: true,
            });
            onSuccess(true)
            setMessage("You have signed up successfully") 
        }
    }

    return(

    <MKBox>
        
        {error && <MKTypography variant="button" color="error" px={2}>{error}</MKTypography> }
        {message && <MKTypography variant="body2" color="info" px={2}>{message}</MKTypography>}
        <Divider sx={{ my: 0 }} />
        {data.verify ?  
            <MKBox p={2}> 
                 { !data.finish && <MKInput label="Sign Up Code" fullWidth name="code" required onChange={onChangeInput} onKeyDown = {onKeyEnterDown} />}
            </MKBox> 
        :
            <MKBox p={2}>
                <MKBox py={2}>
                    <MKInput  label="Display Name" fullWidth name="displayName" required onChange={onChangeInput}/>
                </MKBox>
                <MKBox >
                    <MKInput  label="Handle" fullWidth name="handle" required onChange={onChangeInput} onKeyDown = {onKeyEnterDown} />
                </MKBox>
            </MKBox>
        }
        <Divider sx={{ my: 0 }} />
        { !data.finish ?
            <MKBox display="flex" justifyContent="space-between" p={1.5}>

                <MKButton variant="gradient" color="dark" onClick={dismiss}>
                    cancel
                </MKButton>

                <MKButton variant="gradient" color="primary" onClick={submitSignup}>
                    Submit
                </MKButton>

            </MKBox>
            :
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="primary" onClick={dismiss}> Close</MKButton>
            </MKBox>
        }
    </MKBox>

    )
}

