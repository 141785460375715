 

import React, { useRef, useState, useEffect } from "react"

// react-router-dom components
import { Link, useNavigate } from "react-router-dom"

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
 

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";
 
 // Authentication layout components
import AuthLayout from "components/Layouts/AuthLayout";

 

import { useAuth } from "../contexts/AuthContext"
import {browserSupportsWebAuthn, startRegistration} from '@simplewebauthn/browser';

export default function RestoreAccount() {

    const emailRef = useRef()  
    const registerCodeRef = useRef()
   
    const { recoverAccount, confirmRecoverAccount, registerComplete } = useAuth()

    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [confirmCode, setConfirmCode] = useState(false)

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    
    async function handleRecoverSubmit(e) {
        try {
            setMessage("")
            setError("")
            setLoading(true)
            e.preventDefault() 

            if(confirmCode) {
                let result = await confirmRecoverAccount(emailRef.current.value, registerCodeRef.current.value)
                if (result.challenge){
                    
                    setConfirmCode(true);

                    let attResp = await startRegistration(result);
                    attResp.email = emailRef.current.value;
                   
            
                    let authn = await registerComplete(attResp);
                    if (authn.error) {
                      setError(authn.error.message)
                    }
                    else {
                      setMessage("Authenticator registered!")
                      navigate("/login")
                    }
            
                }
                else {
                    setError(result.error.message)
                }
            }
            else {
                let result = await recoverAccount(emailRef.current.value)
                if (result.status){
                    setMessage(result.message);
                    setConfirmCode(true);
                }
                else {
                    setError(result.error.message)
                }
            }

        } catch (error) {
            console.log("handleRecoverSubmit error ", error);
            if (error.message) {
              setError(error.message)
            }
        }
        finally{
            setLoading(false)
        }
    }


    function cancelRegister(){
        setConfirmCode(false)
        setMessage("")
        setError("")
    }



    
    return (
        <AuthLayout>
        <Card>
            <MKBox
                variant="contained"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
            >
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Restore Account
            </MKTypography>
            
            </MKBox>

            {error && 
                <MKAlert color="error">
                    Whoop!&nbsp; {error}
                </MKAlert>
            }

            {message && 
            <MKAlert color="info">
                {message}
            </MKAlert>
            }

            <MKBox pt={4} pb={3} px={3}>
            <MKBox  component="form"  autoComplete="off" role="form" onSubmit = {handleRecoverSubmit}> 
 

                <MKBox mb={2}>
                    <MKInput type="email" id='emailRef' label="Email" fullWidth inputRef={emailRef}  required />
                </MKBox>

                {confirmCode &&
                <div>
                    <MKBox mb={2}>
                        <MKInput type="number" id='registerCodeRef' label="Confirmed Code" fullWidth inputRef={registerCodeRef}  required />
                    </MKBox> 
                </div>
                    
                } 

                <MKBox mt={4} mb={1} textAlign="center">
                {loading ?   
                    <CircularProgress  color="primary"/>
                    : 
                    <MKButton disabled={loading} variant="contained" type="submit" color="primary" fullWidth>
                        {confirmCode ? "SUBMIT" :  "REQUEST"} 
                    </MKButton>
                }

                </MKBox>

                {confirmCode && 
                    <MKButton type="submit" variant="contained" color="warning" fullWidth >
                        cancel
                    </MKButton> 
                }

                
                <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                        Already have an account?{" "}
                        <MKTypography
                            component={Link}
                            to="/signin"
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                        >
                        Sign In
                        </MKTypography>
                    </MKTypography>
                </MKBox>
            </MKBox>
            </MKBox>
        </Card>
        </AuthLayout>
    );
 

}