 
 

import React, { useRef, useState, useEffect } from "react"
 
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"  
 

import {platformAuthenticatorIsAvailable, startAuthentication, browserSupportsWebAuthn, browserSupportsWebAuthnAutofill} from '@simplewebauthn/browser';
// @mui material components
import Card from "@mui/material/Card"; 
import CircularProgress from '@mui/material/CircularProgress';
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

// Authentication pages components
import AuthLayout from "components/Layouts/AuthLayout";
 
function SignIn() {
 
  const emailRef = useRef()
 
  const { login, loginComplete} = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false) 
  const navigate = useNavigate()


  useEffect(() => { 
    if (!browserSupportsWebAuthn()) { 
      setError( 'It seems this browser does not support Passkey Authentication.');
      return;
    } 

  },[])

  const onKeyEnterDown = (event) => {
    if(event.keyCode == 13) getLoginChallenge()
  }

  const getLoginChallenge = async() => {
    try {
      
      if (!await platformAuthenticatorIsAvailable()) {
        setError("Your device doesn't have Passkey Authenticator. Please use any security key device to register.") 
        return;
      }

      setLoading(true)
      let email = emailRef.current.value;

      if (email === undefined || email === ""){

        setError("Please enter an email")
        return;
      } 

      let result = await login(emailRef.current.value); 
      if (result.error) {
        setError(result.error.message)
        return
      }

      let asseResp = await startAuthentication(result);
      asseResp.email = emailRef.current.value;
      

      let authn = await loginComplete(asseResp);
      if (authn.error) {
        setError(authn.error.message)
      }
      else { 
        localStorage.setItem("user", JSON.stringify(authn));

        navigate("/apps")
      }

      setLoading(false)

    } catch (error) {
      console.log("getRegisterChallenge error ", error);
      if (error.message) {
        setError(error.message)
      }
     
    }
    finally {
      setLoading(false)
    }
  }


 
  return (
    <AuthLayout>
      <Card>
        <MKBox
          variant="contained"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MKTypography>
          
        </MKBox>

        {error && 
          <MKAlert color="error">
            Whoop!&nbsp; {error}
          </MKAlert>
        }

        {message && 
          <MKAlert color="info">
            {message}
          </MKAlert>
        }

        <MKBox pt={4} pb={3} px={3}>
    
          <MKBox  > 
            <MKBox mb={2} autoComplete="off" action={getLoginChallenge}>
             
                <MKInput label="Email" fullWidth inputRef={emailRef} autoFocus required autoComplete="new-password"  onKeyDown = {onKeyEnterDown}/>
             
            </MKBox> 

            <MKBox mt={4} mb={1} textAlign="center">
                {loading ?   
                    <CircularProgress  color="primary"/>
                    : 
                    <MKButton variant="contained" color="primary" fullWidth onClick = {getLoginChallenge}>
                      sign in
                    </MKButton>
                }

            </MKBox>  

            
            <MKBox mt={3} mb={1} textAlign="center"> 

              <MKTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MKTypography>
              </MKTypography>
            </MKBox>

          </MKBox>
        </MKBox>
      </Card>
    </AuthLayout>
  );
}

export default SignIn;
