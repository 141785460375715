import { useState, useEffect } from "react"; 
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import localeList from "components/AppKey/locales.json"
import MenuItem from '@mui/material/MenuItem';

export default function CustomSelection({list, placeholder, defaultValue, selectionHandler}) {

  
    const [items, setItems] = useState([])
    const [label, setPlaceHolder] = useState('Add Item')

    useEffect(() => {

        //console.log("list ", list)

        if(list) setItems(list)
        else setItems(localeList.list) 

        if(placeholder) setPlaceHolder(placeholder)
    }, []) 

    const handleChange = (event) => {

        let item = items.filter(item => item.code == event.target.value)[0] 
        selectionHandler(item)
    };

    
    
    return (

        <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
        <InputLabel htmlFor="customSelection">{label}</InputLabel>
            <Select
                id="customSelection"
                inputProps={{
                    name: 'customSelection',
                    id: 'uncontrolled-native',
                }}
                defaultValue={defaultValue}
                onChange={handleChange}
            > 
           
            {
                items.map(function(item, index){ 
                    return(
                        <MenuItem value={item.code} key={index}> {item.name} - {item.code} </MenuItem> 
                    )
                })
            }


            </Select>
        </FormControl>

    )
}