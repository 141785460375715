

import { useEffect, useState } from "react";
import SiteLayout from "components/Layouts/SiteLayout";
import MKBox from "components/MKBox"; 
import MKTypography from "components/MKTypography"; 


export default function Agreement() {
    return (
        
        <SiteLayout>
            
            <MKBox component="section" >
                <MKBox textAlign="center" p={3}>
                    <MKTypography variant="h3">Agreement</MKTypography>
                </MKBox>
                
                
                <MKTypography pb={3} variant="h5">1. Agreement Overview</MKTypography> 
                <MKTypography pb={3} variant="body2">This Agreement represents a Service Level Agreement (“Agreement”) between Cosync, Inc. (“Cosync”) and the Client (the individual or entity that has ACCEPTED the Agreement) for the AppKey user authentication and identity management of the Client’s users.  Cosync is a Passkey authentication provider for clients building cloud mobile, web, or desktop applications.  Cosync provides secure user authentication and identity management on behalf of the Client. This Agreement remains valid until superseded by a revised agreement mutually endorsed by the Parties.</MKTypography>
                <MKTypography variant="body2">This Agreement outlines the parameters of the AppKey authentication and identity management services as they are mutually understood by the Parties. </MKTypography>
            

                <MKTypography py={3} variant="h5">2. Goals & Objectives</MKTypography> 
                <MKTypography pb={3} variant="body2">The purpose of this Agreement is to ensure that the proper elements and commitments are in place to provide consistent service to the Client and to document the agreement and intent of the Parties. </MKTypography> 
                <MKTypography pb={3} variant="body2">The goal of this Agreement is to obtain mutual agreement for authentication services between Cosync and Client. </MKTypography> 
                <MKTypography pb={3} variant="body2">The objectives of this Agreement are to:</MKTypography>

                <MKTypography pl={3} variant="body2"><b>a.</b> Provide clear reference to service ownership, roles and/ or responsibilities. </MKTypography>
                <MKTypography pl={3} variant="body2"><b>b.</b> Present a clear, concise description of service provision to the Client. </MKTypography>
                <MKTypography pl={3} variant="body2"><b>c.</b> Match perceptions of expected service provision with actual service support & delivery. </MKTypography>


                <MKTypography py={3} variant="h5">3. The Parties</MKTypography> 
                <MKTypography pb={3} variant="body2">The Parties to this Agreement are Cosync and the Client. There are no third party beneficiaries intended or implied by this Agreement whatsoever. </MKTypography> 

                <MKTypography py={3} variant="h5">4. Periodic Review</MKTypography> 
                <MKTypography pl={3} variant="body2"><b>a.</b> This Agreement is valid from the date Client accepts the Agreement and pays for any Services as required by Section 6 and is valid until further notice.</MKTypography> 
                <MKTypography pl={3} variant="body2"><b>b.</b> This Agreement should be reviewed once per fiscal year, however, in lieu of a review during any specified period, the current Agreement will remain in effect.</MKTypography> 


                <MKTypography py={3} variant="h5">5. Service Agreement</MKTypography> 
                <MKTypography pb={3} variant="body2">The following detailed service parameters are the guidelines of Cosync in support of this Agreement.</MKTypography> 
                <MKTypography pl={3} variant="body"><b>a.</b> Scope of Service</MKTypography> 
                <MKTypography pl={5} variant="body2">The following Services are currently covered by this Agreement:</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>i.</b> User authentication</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>ii.</b> User registration</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>iii.</b> User management</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>iv.</b> User signup</MKTypography> 

                <MKTypography p={3} variant="body"><b>b.</b> Client Requirements</MKTypography> 
                
                <MKTypography pl={7} variant="body2"><b>i.</b> Authentication of the users for Client’s application into the AppKey Portal</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>ii.</b> Maintenance of Client’s user list and handles for Client Application</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>iii.</b> Maintenance of Client’s individual user’s email and passkey public key</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>iv.</b> Best efforts by Cosync to continuously authenticate Client’s users seeking to access Client’s application(s).</MKTypography> 

                <MKTypography p={3} variant="body"><b>c.</b> Cosync Requirements</MKTypography> 
                
                <MKTypography pl={7} variant="body2"><b>i.</b> Accurate entry of information by Client’s users</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>ii.</b> Proper transmission of Client user information from Client application(s) properly transmitting user information to log in</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>iii.</b> Client maintaining proper security over its superuser tokens and other appropriate security measures to safeguard its users’ authentication tokens and user information</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>iv.</b> Payment of all fees</MKTypography> 


                <MKTypography p={3} variant="body"><b>d.</b> Assumptions/ Acknowledged Limitations on Services</MKTypography> 
                
                <MKTypography pl={7} variant="body2"><b>i.</b> Due to the nature of AppKey services, the Services are necessarily dependent upon the successful operation and proper functioning of Microsoft Azure, MongoDB Atlas, the Internet, and potentially other programs.</MKTypography> 
                <MKTypography pl={7} variant="body2"><b>ii.</b> It is understood and mutually acknowledged by the Parties that a malfunction or outage among any of these items would necessarily negatively impact Cosync’s ability to deliver the Services. </MKTypography> 
                

                <MKTypography py={3} variant="h5">6. Fees for Services</MKTypography>  
                <MKTypography pl={3} variant="body2"><b>a.</b> Free Tier – for 250 users or less for up to five applications, Client will not be charged a fee but must simply ACCEPT the terms of this Agreement.  If the Client exceeds five applications or if any of the applications exceed 50 users, the Client will be charged according to the Developer Subscription Tier. </MKTypography> 
                <MKTypography pl={3} variant="body2"><b>b.</b> Developer Subscription Tier – for the first 1000 users and up to 20 applications, the Client shall pay $49 per month. </MKTypography> 
                <MKTypography pl={3} variant="body2"><b>c.</b> Developer Subscription plus Tier – for the first 10000 users and up to 100 applications, the Client shall pay $299 per month.  Additional, 10000 users can be purchased for $199 per month.</MKTypography> 
                <MKTypography pl={3} variant="body2"><b>d.</b> Cosync may offer other potential additional services and/or options in the future for such additional fees as the Parties may agree. </MKTypography> 
            
                <MKTypography py={3} variant="h5">7. No Express or Implied Warranties</MKTypography>  
                <MKTypography pb={3} variant="body2">Client accepts Cosync’s services “as is.”  Cosync will use its best efforts to provide continuous services to Client, however Cosync makes no warranties express or implied that its services will be free from interruption or unexpected conditions.  Cosync hereby disclaims any and all warranties express or implied regarding its services, and Cosync makes no representation that its services are fit for a particular purpose.</MKTypography> 
                <MKTypography pb={3} variant="body2">To the extent the Client experiences losses due to Cosync’s service, Cosync’s liability is limited to a refund of the fees it charges for its services to the Client.</MKTypography>
            

                <MKTypography py={3} variant="h5">8. Ownership of Data</MKTypography>  
                <MKTypography pb={3} variant="body2">Cosync will own all data regarding Client’s users’ activity logging into Client’s applications via’s Cosync’s authentication and identity verification processes.  Client shall make all necessary disclosures regarding Cosync’s ownership and use of Client’s users’ data to its users, and to the extent necessary Client warrants and represents it will procure all consents necessary by operation of law from its users to effectuate this term.</MKTypography> 
                
                <MKTypography py={3} variant="h5">9. Governing Law</MKTypography>  
                <MKTypography pb={3} variant="body2">The Parties agree that this Agreement was entered into in the State of North Carolina, and that this Agreement will be governed by, construed, and enforced in accordance with the laws of the State of North Carolina without regard to conflict of law principles.</MKTypography> 
                
            
                <MKTypography py={3} variant="h5">10. Disputes</MKTypography>  
                <MKTypography pl={3} variant="body2"><b>a.</b> Indemnification – if anyone brings a claim, cause of action, or dispute against Cosync related to services, actions, content or information, you agree to indemnify, defend and hold us harmless from and against all damages, losses, and expenses of any kind (including the cost of our defense) related to any such claim, cause of action or dispute.</MKTypography> 
                <MKTypography pl={3} variant="body2"><b>b.</b> IndemniDispute Resolution</MKTypography> 
                <MKTypography pl={5} py={3} variant="body2">If Client resides or is located wholly outside the United States any claim, cause of action, or dispute between Client and Cosync regarding any matter arising in any way from this Agreement must be resolved exclusively in the United States District Court for the Eastern District of North Carolina or a State Court located in Wake County, North Carolina, and the Parties irrevocably submit to the personal jurisdiction of either of these courts for the purpose of litigating any such claim, and that the laws of the State of North Carolina will govern this Agreement without regarding to conflict of law provisions. </MKTypography> 
                <MKTypography pl={5} py={3} variant="body2">If Client resides or is located in the United States, any claim, cause of action, or dispute between Client and Cosync regarding any matter arising in any way arising from this Agreement then the Parties shall submit the matter to binding arbitration to be held within the State of North Carolina.  The Parties waive their respective rights to a trial by jury or to participate in a class or representative action.  The Federal Arbitration Act shall govern the interpretation and enforcement of this arbitration provision.  All issues are to be decided by an arbitrator, except </MKTypography>

                <MKTypography py={3} variant="h5">11. Other</MKTypography>  
                <MKTypography pl={3} variant="body2"><b>a.</b> If any portion of this Agreement is found to be unenforceable, then (except as otherwise provided) that portion will be severed and the remaining portion will remain in full force and effect. </MKTypography> 
                <MKTypography pl={3} variant="body2"><b>b.</b> If Cosync fails to enforce any term in this Agreement it will not be considered a waiver. </MKTypography>
                <MKTypography pl={3} variant="body2"><b>c.</b> Any amendment to or waiver of the terms in this Agreement by either party must be in writing signed by both Parties. </MKTypography>
                <MKTypography pl={3} variant="body2"><b>d.</b> All of Cosync’s rights under this Agreement are freely assignable in connection with a merger, acquisition, sale of assets, or by operation of law or otherwise. </MKTypography>
                <MKTypography pl={3} variant="body2"><b>e.</b> Cosync reserves all rights not expressly granted to Client in this Agreement. </MKTypography>
 

            </MKBox>

        </SiteLayout>
    )
}