

import { useState, useEffect } from "react";
import MKTypography from "components/MKTypography"; 
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import MKAlert from "components/MKAlert";


import Dropdowns from "components/AppKey/Dropdowns";
import { useNavigate } from "react-router-dom"
import { appAuth } from "../../contexts/AppContext"

export default function AppSetting({app}) { 

    const navigate = useNavigate() 
    const { setLoadingSuccessData, updateApp, deleteApp, resetApp , allApps} = appAuth()
    
    const [errorMessage, setErrorMessage] = useState("");
    const [appState, setAppState] = useState({showDelete:false, showClearAppData:false})
    const [appData, setAppData] = useState(app)

    const toggleModalDelete = () =>  setAppState({...appState, ["showDelete"]: !appState.showDelete}) 

    const toggleModalClearData  = () =>  setAppState({...appState, ["showClearAppData"]: !appState.showClearAppData})



    useEffect(() => { 
        setAppData(app)
        console.log("AppSetting app  ", app.name)  
            
    }, [])


    const updateAppNameHandler = () =>{
        console.log("app name ", appData.name) 

        let checked = allApps.filter(app => app.name == appData.name)[0];
        if (checked){  
            setErrorMessage(`App name '${appData.name}' already exists.`)
        }  
        else updateAppData(appData.name, "name")

    }


    const onChangeValue = (evt)=> {
        setAppData({
            ...appData,
            [evt.target.name]: evt.target.value
        })
    }



    const updateAppData = async (value, option) => { 

        let result = await updateApp(app.appId, option, value)

        console.log("updateAppData " , result)
        if(result.error) setErrorMessage(`Whoop! ${result.error.message}.`)
    }


    const deleteAppHandler = async () => {
        toggleModalDelete() 
        let result = await deleteApp(app.appId);
        if(result === true) {
            navigate("/apps")
        }
        else setErrorMessage(`Whoop! ${result.error.message}.`)
    }


    const clearAppDataHandler = async () => {
        toggleModalClearData()
        let result = await resetApp(app.appId);
        if(result.error) setErrorMessage(`Whoop! ${result.error.message}.`)
        if(result) setLoadingSuccessData({message:"Your App has been cleared."})
    }


    return( 
        <MKBox p={2}>

            {errorMessage != "" && <MKAlert color="error" dismissible>Whoop!{errorMessage}</MKAlert>}
           
            <MKTypography variant="h5">Application Settings</MKTypography>
            
            <Container >
                
                <Grid container spacing={1} mt={2} pb={5}>
                    <Grid item xs={12} md={5} lg={5}>

                        <Stack spacing={3}>
                            <MKBox component="section" display="flex">  
                                <MKInput variant="standard" label="App ID" disabled value={app.displayAppId}  /> 
                                
                                <MKButton  color="clear" onClick={() => {navigator.clipboard.writeText(app.displayAppId)}}>
                                    <Icon fontSize="large">copy</Icon>
                                </MKButton>
                                
                            </MKBox>
                            
                            <MKInput variant="standard" label="App Name" id="appName" name='name' defaultValue={app.name} onChange={onChangeValue} required fullWidth  />

                            <MKBox  sx={{ my: 2 }} justifyContent="left" >
                                <MKButton variant="gradient" color="primary" onClick={updateAppNameHandler}>
                                    Save
                                </MKButton>
                            </MKBox>

                            <MKTypography variant="h6" color="text">
                                RESET APP DATABASE 
                            </MKTypography>
                            <MKTypography variant="body2" color="text"> 
                                This will clear your App data from AppKey Portal
                            </MKTypography>
                            <MKBox  sx={{ my: 2 }} justifyContent="left" >
                                <MKButton variant="gradient" color="error" onClick={toggleModalClearData}>
                                    clear
                                </MKButton>
                            </MKBox>

                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={2} lg={2}>

                    </Grid>

                    <Grid item xs={12} md={5} lg={5} >
                        <Stack spacing={3}>

                            <MKTypography variant="h6" color="text">
                                EXPORT APP DATABASE 
                            </MKTypography>
                            <MKTypography variant="body2" color="text"> 
                                This will export your App data from AppKey Portal
                            </MKTypography>
                            <MKBox  sx={{ my: 2 }} justifyContent="left" >
                                <MKButton variant="gradient" color="primary" >
                                    export
                                </MKButton>
                            </MKBox>

                            <MKBox alignItems="center" justifyContent="left" display="flex" >
                                <MKTypography variant="h6" color="text">
                                    APP STATUS:
                                </MKTypography>

                                <Dropdowns items={["ACTIVE",  "INACTIVE"]} defaultValue = { app.status.toUpperCase()}  onChange={(val) => { 
                                    updateAppData(val.toLowerCase(), "status")
                                }} />

                                

                            </MKBox>

                            <MKTypography variant="h6" color="text">
                                DELETE APP
                            </MKTypography>
                            <MKTypography variant="body2" color="text"> 
                                This will delete your App entirely from AppKey Portal
                            </MKTypography>
                            <MKBox  sx={{ my: 2 }} justifyContent="left" >
                                <MKButton variant="gradient" color="error" onClick={toggleModalDelete}>
                                    delete
                                </MKButton>
                            </MKBox>

                        </Stack>
                    </Grid>

                </Grid>
            </Container>



            <Modal open={appState.showClearAppData} onClose={toggleModalClearData} sx={{ display: "grid", placeItems: "center" }}>
                <Slide direction="down" in={appState.showClearAppData} timeout={500}>
                    <MKBox
                    position="relative"
                    sx={{ maxWidth: 'md' }}
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                    >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Are you sure to clear this app data?</MKTypography> 
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox p={2}>
                        <MKTypography variant="body">This transaction can't undo.</MKTypography>
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={toggleModalClearData}>
                        cancel
                        </MKButton>
                        <MKButton variant="gradient" color="primary" onClick={clearAppDataHandler}>
                        Submit
                        </MKButton>
                    </MKBox>
                    </MKBox>
                </Slide>
            </Modal>
                

            <Modal open={appState.showDelete} onClose={toggleModalDelete} sx={{ display: "grid", placeItems: "center" }}>
                <Slide direction="down" in={appState.showDelete} timeout={300}>
                    <MKBox
                    position="relative"
                    sx={{ maxWidth: 'md' }}
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                    >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Are you sure to delete this app?</MKTypography> 
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox p={2}>
                        <MKTypography variant="body">This transaction can't undo.</MKTypography>
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={toggleModalDelete}>
                        cancel
                        </MKButton>
                        <MKButton variant="gradient" color="primary" onClick={deleteAppHandler}>
                        Submit
                        </MKButton>
                    </MKBox>
                    </MKBox>
                </Slide>
            </Modal>


        </MKBox> 



       
    )
}
