import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography"; 

function Dropdowns({items, defaultValue, onChange}) {
    const [dropdown, setDropdown] = useState(null);
    const [dropup, setDropup] = useState(null);

    const [value, setValue] = useState(defaultValue || "Active");

    const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
    const closeDropdown = () => setDropdown(null);

    const selectDropdownValue = (val) => {
        setDropdown(null)
        setValue(val)
        onChange(val)
    };
 
  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };
 

  const renderItem = () =>  
     items.map( item => (
        <MenuItem key={item} onClick={() => selectDropdownValue(item)} >{item}</MenuItem> 
    
  ));

  return (
    <MKBox component="section">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} textAlign="center" sx={{ cursor: "pointer" }}>
            <MKTypography variant="h6" color="info" onClick={openDropdown}>
              {value} <Icon sx={dropdownIconStyles}>expand_more</Icon>
            </MKTypography>
            <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
                {renderItem()}
            </Menu>
          </Grid>
           
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Dropdowns;