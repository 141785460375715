

import { useState, useEffect } from "react";
import MKTypography from "components/MKTypography"; 
import Icon from "@mui/material/Icon";
import MKInput from "components/MKInput";
import Divider from "@mui/material/Divider";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox"; 
import { appAuth  } from "../../contexts/AppContext"
import { startAuthentication, startRegistration } from '@simplewebauthn/browser';

export default function AppLogin({app, anonymous, onSuccess, dismiss}) { 

    const {loginAnonymous, loginAnonymousComplete, login, loginComplete, signupComplete} =  appAuth()
    const [message, setMessage] = useState("")
    const [error, setErrorMessage] = useState("")
    const [data, setData] = useState({})

    useEffect(() => {
       setData({appId:app.appId})
    }, [])

    const onChangeInput = (evt) => { 

        setData({
            ...data,
            [evt.target.name]: evt.target.value,
        });
    }

 
    const submitLogin = async () => {
        setErrorMessage("")
        let result;
        if (anonymous) {
            data.handle = `ANON_${Math.random()}`
            result = await loginAnonymous(data); 
            console.log("loginAnonymous result ", result)
           
        }
        else {
            if(data.handle === "" ||  data.handle === undefined){
                setErrorMessage("Please enter user handle")
                return
            } 
            result = await login(data); 
        }
       
       
        if (result.error) {
            setErrorMessage(result.error.message)
            return
        } 
        
        else if (result.requireAddPasskey === true || anonymous){

            setData({
                ...data,
                ['handle']: result.user.handle,
            });

            console.log("startRegistration result =  ", result)
            let attResp = await startRegistration(result);

            console.log("startRegistration attResp =  ", attResp)

            attResp.handle = result.user.handle;
             
            attResp.appId = app.appId;

            let user;
            if (anonymous) user = await loginAnonymousComplete(attResp);
            else  user = await signupComplete(attResp);

            if (user.error) {
                setErrorMessage(user.error.message)  
            }
            else {  

                if(anonymous) onSuccess(user)
                    
                setData({
                    ...data,
                    finish: true,
                });

                setMessage("You have login successfully") 
            }  
        }
        else {
            try { 
                console.log("startAuthentication challenge =  ", result)
                let attResp = await startAuthentication(result); 
                attResp.handle = result.user.handle;
               
                attResp.appId = app.appId;
                
                let authn = await loginComplete(attResp);
                if (authn.error) {
                    setErrorMessage(authn.error.message)
                }
                else {
                    setData({
                        ...data,
                        finish: true,
                    });

                    setMessage("You have login successfully") 
                }

            } catch (error) {
                setErrorMessage(error.message)  
            }
        }
        
    }


    const onKeyEnterDown = (event) => {
        console.log("onKeyEnterDown keyCode ", event.keyCode)
        if(event.keyCode == 13) submitLogin()
    }

    return(

    <MKBox>
        
        {error && <MKTypography variant="button" color="error" px={2}>{error}</MKTypography> }
        {message && <MKTypography variant="body2" color="info" px={2}>{message}</MKTypography>}
        <Divider sx={{ my: 0 }} />

        <MKBox display="flex" alignitems="center" justifyContent="space-between" p={2}>
            <MKTypography variant="h5">Login to Application '{app.name}'</MKTypography>
            
        </MKBox>
       

        { anonymous ?  <MKTypography variant="body2" color="text">Login with anonymous user</MKTypography>
        :
        <MKBox p={2}>
             <MKBox display="flex" alignitems="center" justifyContent="space-between" px={2}>
            <MKTypography variant="body2" color="text">Enter your user handle</MKTypography>
        </MKBox>
            <MKInput  label="Handle" fullWidth name="handle" required onChange={onChangeInput} onKeyDown = {onKeyEnterDown}/>
        </MKBox>
        }

        <Divider sx={{ my: 0 }} />
        { !data.finish ?
            <MKBox display="flex" justifyContent="space-between" p={1.5}>

                <MKButton variant="gradient" color="dark" onClick={dismiss}>
                    cancel
                </MKButton>

                <MKButton variant="gradient" color="primary" onClick={submitLogin}>
                    Login
                </MKButton>

            </MKBox>
            :
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="primary" onClick={dismiss}> Close</MKButton>
            </MKBox>
        }
    </MKBox>

    )
}

