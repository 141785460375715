
 
 
import React, { useContext, useState, useEffect } from "react"
import { Config } from "../config/Config" 
 

const AuthContext = React.createContext()
let config = process.env.REACT_APP_SERVER ===  'dev' ? Config.DEV : process.env.REACT_APP_SERVER ===  'prod' ? Config.PROD : Config.LOCAL;

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {

  
  const [loading, setLoading] = useState(false) 
 
  const [requestSuccess, setLoadingSuccessData] = useState() 
  const [requestError, setRequestError] = useState()
  const [currentUser, setCurrentUser] = useState() 
  const [paymentAccount, setPaymentAccount] = useState({plans:[], customer:{}}) 
  var loggedInUser


  const countryList = [
   
  ];

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    setLoadingSuccessData()
    if (loggedInUser && loggedInUser["access-token"]) {
      try {
        const foundUser = JSON.parse(loggedInUser); 
        setCurrentUser(foundUser)
 
      } catch (error) {
        
      }
     
    }
    
  }, [])

  async function apiRequest(method, path, data, showLoading = true, file){ 

      const userCache = localStorage.getItem("user"); 
    
      if (userCache) {
        loggedInUser = JSON.parse(userCache); 
      }  

      setRequestError(null)
      if(showLoading) setLoading(true) 

      try {
          let requestOptions = {
              method: method || 'POST',
              headers: {
                        "access-token": loggedInUser && loggedInUser["access-token"]  ? loggedInUser["access-token"] : "",
                        "signup-token": loggedInUser && loggedInUser["signup-token"]  ? loggedInUser["signup-token"] : ""
                      } 
          };

          if (method !== "GET" && method !== "DELETE"){
              requestOptions.body = JSON.stringify(data)
          }
          

          if (file) {
            const formData = new FormData();  
            formData.append('file', file); 
            requestOptions.body = formData
          }
          else {
            requestOptions.headers['Content-Type'] = 'application/json'     
          }

          let endpoint = `${config.REST_API}/api/${path}`

          console.log("apiRequest endpoint ", endpoint)

          const response = await fetch(endpoint, requestOptions); 
          let result = await response.json();

          console.log("apiRequest result ", result)
          
          if (response.status !== 200){
              setRequestError(result);
              if(result.code === 405) logout()
              console.log("apiRequest setRequestError ", result)
              return {error:result}
          } 
          else{
              return result;
          } 


      } catch (error) {
           console.log("apiRequest error ", error)
          setRequestError(error)
          return {error:error}
      }
      finally{
          setLoading(false)
      }
  }


  async function recoverAccount(email) {
    try {  
      return await apiRequest("POST", "authn/recover", { email: email }, false)  
       
    } catch (error) { 
      return  {error:error}
    } 
  }
   

  async function confirmRecoverAccount(email, code) {
    try { 
     
      return await apiRequest("POST", "authn/recoverComplete", { email: email, code:code }, false)  

    } catch (error) { 
      return  {error:error}
    } 
  }

  async function register(data) {

    try { 
      logout()
      return await apiRequest("POST", "authn/register", data, false)   

    } catch (error) { 
      return  {error:error}
    } 

  }



  async function confirmRegister(data) {
    let response = await apiRequest("POST", "authn/registerConfirm", data, false) 
    if (!response.error){ 
      localStorage.setItem('user', JSON.stringify(response));
      
    } 
    return response;
  }



  async function registerComplete(authData) {
    try { 

      let response = await apiRequest("POST", "authn/registerComplete", authData, false)

      if (!response.error){
        // store the user in localStorage 
        const now = new Date()
        const userCache = {
          data: response,
          expiry: now.getTime() + 2000000,
        }; 
        localStorage.setItem('user', JSON.stringify(response));
      } 

      setCurrentUser(response)
      
      return response;

    } catch (error) {
      return  {error:error}
    }
  }
 

  async function login(email, clear = true) {
    try {

      if(clear) localStorage.clear(); 

      return await apiRequest("POST", "authn/login", { email: email }, false)

       
    } catch (error) {
      console.log(error)
      return {error:error}
    }
  }


  async function loginComplete(assert) {
    try {

      let response = await apiRequest("POST", "authn/loginComplete", assert, false)

      if (!response.error) {

         // store the user in localStorage 
        const now = new Date()
        const userCache = {
          data: response,
          expiry: now.getTime() + 2000000,
        }; 
        localStorage.setItem('user', JSON.stringify(response));
        setCurrentUser(response) 
      } 

      return response;

    } catch (error) {
      console.error(error)
      return {error:error}
    }
  }



  async function addPasskey(email) {
    let response = await apiRequest("POST", "authn/addPasskey", { email: email}, false)
  }




  async function addPasskeyComplete(authData) {
    return await apiRequest("POST", "authn/addPasskeyComplete", authData, false)
  }

  async function getUser(){
    let response = await apiRequest("GET", "authn/user", null, false)
    if (!response.error){
      localStorage.setItem('user', JSON.stringify(response));  
      setCurrentUser(response)
    } 

    return response;
  }



  async function updateProfile(profile){
    let response = await apiRequest("POST", "authn/updateProfile", profile, false)
    if (!response.error){
     

      updateUserCache('firstName', profile.firstName)
      updateUserCache('lastName', profile.lastName)
      updateUserCache('company', profile.company)
    } 

    return response;
  }

  function updateUserCache(key, value) {

    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      try {

        let foundUser = JSON.parse(loggedInUser); 
        if (key === 'replace') foundUser = value
        else foundUser[key] = value

        console.log("updateUserCache data ", foundUser)


        localStorage.setItem('user', JSON.stringify(foundUser));   

        if(key !== 'replace') {
          setCurrentUser({
            ...currentUser,
            [key]:value 
          })
        }
        else setCurrentUser(foundUser)

      } catch (error) {
        console.error("cannot get user catche..error ", error)
      } 
    }
    else {
      console.error("cannot get user catche...")
    }
  }


  async function removePasskey(keyId) {
    try {  

      let response = await apiRequest("POST", "authn/removePasskey", { keyId : keyId }, false)
      if (!response.error){
        // localStorage.setItem('user', JSON.stringify(response));  
        // setCurrentUser(response)
        updateUserCache('replace', response)
      }

      return response;

    } catch (error) {
      console.error(error)
      return {error:error}
    }
  }
 



  async function updatePasskey(authData) {
    try { 
    
      let response = await apiRequest("POST", "authn/updatePasskey", authData, false)
      if (!response.error){
         
        updateUserCache('replace',response)
        
      } 

      return response;

    } catch (error) {
      console.log(error)
      return {error:error}
    }
  }



  async function getPlans(){
    return await apiRequest("GET", "payment/plan", null, false)  
  }


  function logout() {
   
    setCurrentUser();
   
    localStorage.clear();
    return true;
  }
 
  async function addPaymentMethod(params) {
    let result = await apiRequest("POST", "payment", params, false)   
    
    return result
  }

  async function subscribe(params, token) {
    params.token = token
    let result = await apiRequest("POST", "payment/subscribe", params, true)  
    if(!result.error){ 
      let text = `You have successfuly subscribed "${result.plan.name}". Your subscription will renew at ${result.nextBillingDate}.`
      setLoadingSuccessData({title : "Success! Thank you for your subscription with AppKey.", message: text})
      updateUserCache("planId", result.plan.developerPlanId)
    }  
    return result
  }

  async function getSubscriptionsAccount() { 

    let result = await apiRequest("GET", "payment/subscriptions", null, true)

    if (!result.error) {
      setPaymentAccount({
        ...paymentAccount, 
        customer: { 
          ...paymentAccount.customer, 
          paymentMethods : result.customer.paymentMethods,
          adresses : result.customer.adresses,
          creditCards : result.customer.creditCards,
        },
        plans:{
          ...paymentAccount.plans,
          plans:result.plans
        }
      });

      if(result.user)  localStorage.setItem('user', JSON.stringify(result.user));
    }

   
    return result;
  }



  async function updateAddress(address) { 
    let acc = await apiRequest("POST", "payment/address", address, true)   
    setPaymentAccount(acc)
    return acc;
  }


  async function updateAutoRenewSubscription(sub, value) { 
    sub.autoRenew = value
    let result = await apiRequest("POST", "payment/updateAutoRenewSubscription", sub, true)
    return result
  }


  async function uploadAvatar(file) {

    try {  
      let avatar = await apiRequest("POST", "storage/upload", {} , true, file)   
      if(!avatar.error){

        updateUserCache('avatar', avatar)
        return avatar
        
      }
    } catch (error) { 
      return  {error:error}
    } 

  }

  async function getBillingHistory () {
    let result = await apiRequest("GET", "payment/billingHistory", null, true)
    return result
  }

  async function getInvoice(invoice) {

    const userCache = localStorage.getItem("user"); 
    
    if (userCache) {
      loggedInUser = JSON.parse(userCache); 
    }  

    setRequestError(null)
    setLoading(true) 

    console.log("getInvoice invoice ", invoice)


    let requestOptions = {
      method: 'GET',
      headers: { 
          "access-token": loggedInUser && loggedInUser["access-token"]  ? loggedInUser["access-token"] : "",
         
      } 
    };
    let endpoint = `${config.REST_API}/api/payment/report?invoiceId=${invoice.invoiceId}` 
         
    const response = await fetch(endpoint, requestOptions); 
    console.log("getInvoice response ", response)

    setLoading(false) 

    if (response.status !== 200){
      setRequestError(response); 
      return {error:response}
    } 
    else return response; 

  }



  const verify = async () => {
      let result = await apiRequest("POST", `authn/verify`, {}, true)  
      return result
  }

  const verifyComplete = async (data) => {
    let result = await apiRequest("POST", `authn/verifyComplete`, data, true)  

    if (!result.error) { 
      localStorage.setItem('user', JSON.stringify(result));
      setCurrentUser(result) 
    }  
    return result
  }

  const getAssociatedDomains = async() => {

    let requestOptions = {
      method:  'GET', 
    };

    console.log("getAssociatedDomains requestOptions ", requestOptions)

    let endpoint = `${config.REST_API}/apple-app-site-association`
    const response = await fetch(endpoint, requestOptions);  
    let result = await response.json();

    console.log("getAssociatedDomains result ", result)
    return result
  }

  

  const value = {
    config,
    countryList,
    currentUser, 
    paymentAccount,
    requestSuccess,
    requestError,
    loading,
    verify,
    verifyComplete,
    setLoadingSuccessData,
    setRequestError,
    login,  
    loginComplete,
    register,
    confirmRegister,
    registerComplete,
    logout,
    recoverAccount,
    confirmRecoverAccount,
    addPasskey,
    addPasskeyComplete,
    updatePasskey,
    removePasskey,
    getUser,
    updateProfile,
    getPlans,
    getSubscriptionsAccount,
    updateAddress,
    uploadAvatar,
    subscribe,
    addPaymentMethod,
    updateAutoRenewSubscription,
    getBillingHistory,
    getInvoice,
    getAssociatedDomains
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
