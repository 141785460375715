/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
 
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React base styles
import typography from "assets/theme/base/typography";
 


const routesFooter =  {
    company: { href: "./", name: "App Key" },
    links: [
      { href: "https://appkey.io", name: "Company" },
      { href: "https://appkey.io", name: "About Us" },
      { href: "https://appkey.io", name: "Team" },
      { href: "https://appkey.io", name: "Products" }, 
      { href: "https://appkey.io", name: "License" },
    ],
     
    light: false,
  };

function Footer() {
    const { href, name } = routesFooter.company;
    const { light } = routesFooter.light;
    const { size } = typography; 

    const renderLinks = () =>
        routesFooter.links.map((link, key) => (
        <MKBox
            key={link.name}
            component="li"
            pl={key === 0 ? 0 : 2}
            pr={key === routesFooter.links.length - 1 ? 0 : 2}
            lineHeight={1}
        >
            <Link href={link.href} target="_self">
                <MKTypography variant="button" fontWeight="regular" color={light ? "white" : "text"}>
                    {link.name}
                </MKTypography>
            </Link>
        </MKBox>
    ));

    return (
    <Container>
        <MKBox
            py={3}
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
        >
            <MKBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? "white" : "text"}
            fontSize={size.sm}
            >
              
            <Link href={href} target="_blank">
                <MKTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
                Home
                </MKTypography>
            </Link>
            
            </MKBox>
            <MKBox
            component="ul"
            sx={({ breakpoints }) => ({
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                listStyle: "none",
                mt: 3,
                mb: 0,
                p: 0,

                [breakpoints.up("lg")]: {
                mt: 0,
                },
            })}
            >
            {/* {renderLinks()} */}

                <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                color={light ? "white" : "text"}
                fontSize={size.sm}
                >
                &copy; {new Date().getFullYear()}, Cosync, Inc.
                <Link href={href} target="_blank">
                    <MKTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
                    &nbsp;{name}&nbsp;
                    </MKTypography>
                </Link>
                - Application Passkey Enabler
                </MKBox>
                
            </MKBox>
        </MKBox>
    </Container>
    );
}

export default Footer;
