

import React from 'react';
 
import App from './App';
import { BrowserRouter } from "react-router-dom";
import "./index.css"
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'contexts/AuthContext';
import { AppProvider } from 'contexts/AppContext';
 
 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>  
        <AppProvider>
          <App />
        </AppProvider>
      </AuthProvider>
    </BrowserRouter>
   
  </React.StrictMode>
);
reportWebVitals();

 