import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function getStyles(value, selectedValue, theme) {
  return {
    fontWeight:
    selectedValue.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({list, defaultValue, label, width, multiple, onSelectedValue, resetSelection}) {



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: { 
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
    },
  },
};
 

  const theme = useTheme();
  const [selectedValue, setSelectedValue] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    onSelectedValue(value)
  };



  React.useEffect(() => { 
    if (defaultValue !== undefined ) {
      setSelectedValue([defaultValue])
    }

  }, [])


  React.useEffect(() => { 
    
      setSelectedValue([]) 

  }, [resetSelection])

  return (
    
      <FormControl sx={{  width: width ? width : "100%"}}>
        <InputLabel id="demo-multiple-item-label" >{label}</InputLabel>
        <Select
            labelId="demo-multiple-item-label"
            id="demo-multiple-item"
            multiple={multiple}
            value={selectedValue} 
            onChange={handleChange}
            input={<OutlinedInput label={label} />}
            sx={{height: ITEM_HEIGHT }}
            MenuProps={MenuProps}
        >
          {list.map((item) => (
            <MenuItem
              key={item}
              value={item}
              style={getStyles(item, selectedValue, theme)}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    
  );
}